import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { GenesysCloudWebrtcSdk } from 'genesys-cloud-webrtc-sdk';
import { assignHDPhone, userLogout } from '../../services';
import { withOktaAuth } from '@okta/okta-react';
import { logger } from '../../services/server_side_logging';
import { consts } from '../../config/consts';
import { updateErrorMsg } from '../../store/action/error';
import { Feedback } from '../../utils';
import { updateGenesysConnection } from '../../store/action/genesysConnection';
import { getGenesysDomain } from '../../services/genesys';
var jwtDecode = require('jwt-decode');

const webRTCLogger = {
  log: (message, details) => {
    logger.info('GenesysWebrtc log', { level: 'log', message, details });
  },
  debug: (message, details) => {
    logger.info('GenesysWebrtc log', { level: 'debug', message, details });
  },
  info: (message, details) => {
    logger.info('GenesysWebrtc log', { level: 'info', message, details });
  },
  warn: (message, details) => {
    logger.warn('GenesysWebrtc log', { level: 'warn', message, details });
  },
  error: (message, details) => {
    logger.error('GenesysWebrtc log', { level: 'error', message, details });
  },
};

const GenesysWebRTCSoftphone = props => {
  const {
    genesysConnectionState,
    genesysEnvironment,
    authService,
    authState,
    updateSnackMessage,
    closeGenesysWSConnection,
  } = props;

  const { accessToken, genesysUserId } = genesysConnectionState.token;
  const [dialogOpen, setDialogOpen] = useState(false); // Track dialog open state
  const [dialogMessage, setDialogMessage] = useState(''); // Track dialog message
  const [dialogTitle, setDialogTitle] = useState(''); // Track dialog title
  const [countdown, setCountdown] = useState(10); // Countdown timer for logout

  const showAlertDialog = (title, message) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogOpen(true);

    // Start countdown timer
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          handleLogout();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleLogout = () => {
    setDialogOpen(false);

    if (genesysConnectionState.ws) {
      genesysConnectionState.ws.close();
    }

    // might not need to set wsStatus to closed because we're logging out.
    closeGenesysWSConnection();

    userLogout('logged out by disconnecting from genesys mediahelper');

    logger.warn(
      'Genesys ws closed and user logged out by disconnecting from genesys media helper',
      { oktaToken: authState?.accessToken }
    );

    authService.logout('/');
  };

  useEffect(() => {
    const oktaToken = authState?.accessToken
      ? jwtDecode(authState.accessToken)
      : null;
    if (!oktaToken) {
      return;
    }
    if (!consts.telephony.isGenesys(oktaToken.telephony)) {
      return;
    }
    if (!consts.phoneType.isSoftphone(oktaToken.phone_type)) {
      return;
    }

    if (!accessToken) {
      return;
    }

    // assign softphone before initialazing
    const { sub: email, legacy_username: legacyUser } = oktaToken;

    const userEmail = legacyUser ? legacyUser : email;

    let sdk = null;

    async function initSoftphone() {
      try {
        const resp = await assignHDPhone({
          user_id: userEmail,
          phone_id: 'softphone',
        });
        if (resp.status === 200) {
          logger.info('assignHDPhone for Genesys softphone is successful', {
            userToken: oktaToken,
          });
        } else {
          showAlertDialog(
            'Softphone Warning',
            'Assign Genesys softphone failed.'
          );

          logger.error('assignHDPhone for Genesys softphone failed', {
            userToken: oktaToken,
            resp: resp,
          });
        }
      } catch (error) {
        showAlertDialog(
          'Softphone Warning',
          'Assign Genesys softphone failed.'
        );

        logger.error('assignHDPhone for Genesys softphone failed', {
          error: error,
          userToken: oktaToken,
        });
      }

      // get Genesys domain from backend which is configured in Vault
      let genesysDomain = await getGenesysDomain(
        oktaToken['communicator-integration-id']
      );
      if (!genesysDomain) {
        genesysDomain = genesysEnvironment;
      }

      // continue to init the softphone regardless of the assignment
      // 1. agent can still use Genesys UI for selecting softphone.
      // 2. softphone may be set as default station.
      try {
        sdk = new GenesysCloudWebrtcSdk({
          accessToken: accessToken,
          environment: genesysDomain,
          logger: webRTCLogger,
        });

        sdk.initialize();

        // Listen for the `error` event
        sdk.on('sdkError', error => {
          // what to do if sdk is not initializing? logout? confirm with Brent. we can reuse the AlertDialog
          logger.error('soft-phone sdk event Error occurred', {
            error,
            oktaToken,
            genesysDomain,
            genesysToken: genesysConnectionState.token,
          });

          if (
            error?.details?.errorInfo?.code
              .toLowerCase()
              .includes('mediahelperrequired')
          ) {
            logger.error('Media Helper is required', {
              error,
              oktaToken,
              genesysDomain,
              genesysToken: genesysConnectionState.token,
            });

            showAlertDialog(
              'Media Helper Warning',
              'Media Helper is not running. Please run it. You will be logged out in 10 seconds.'
            );
          }
        });

        sdk.on('ready', () => {
          updateSnackMessage({
            error_msg: 'Genesys WebRTC initialized successfully',
            error_severity: Feedback.INFO,
          });
        });

        // Additional SDK event listeners as needed...
      } catch (error) {
        // what to do if softphone is not working? logout? confirm with Brent. we can reuse the AlertDialog
        logger.error('SDK initialization failed', {
          error,
          oktaToken,
          genesysDomain,
          genesysToken: genesysConnectionState.token,
        });
      }
    }

    initSoftphone();

    return () => {
      if (sdk) {
        sdk.disconnect();
      }
    };
  }, []);

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage} Logging out in {countdown} seconds.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  const { genesysEnvironment } = state.environmentState;
  return {
    genesysConnectionState: state.genesysConnectionState,
    genesysEnvironment,
  };
};

const mapDispatchToProps = dispatch => ({
  updateSnackMessage: msgObj => {
    dispatch(updateErrorMsg(msgObj));
  },
  closeGenesysWSConnection: () => {
    dispatch(
      // leave auth status and token as it is
      updateGenesysConnection({
        wsStatus: 'closed',
        ws: null,
      })
    );
  },
});

export default withOktaAuth(
  connect(mapStateToProps, mapDispatchToProps)(GenesysWebRTCSoftphone)
);
